import { types } from "../actions/brand/types";
const initialState = {
  name: "",
  focus: "",
  age: "",
  website: "",
  zipcode: "",
  statecode: "",
  brandId: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_BRAND:{
      return { ...state, ...action.payload };}
    default:
      return state;
  }
};
