import { combineReducers } from "redux";
import user from "./user";
import product from './products'
import brand from "./brand";

export default combineReducers({
  data: user,
  products:product,
  brand: brand
});
