import { types } from "../actions/product/types";
const defaultSkip = Math.floor(Math.random() * (100 - 0 + 1) + 0);
const initialState = {
  items: [],
  product: {},
  count: 0,
  pantones: [],
  wishlist: [],
  colors: [],
  images: [],
  orderDetails: {},
  defaultSkip: defaultSkip,
  type: "apparel",
  filters: {
    gender: [],
    style: [],
    fit: [],
    textile: [],
    search: "",
    novelty: [],
    customs: [],
    skip: defaultSkip,
    limit: process.env.PRODUCT_LIMIT,
    priceSort: "",
  },
  noMoreProducts: false,
  graphicsOpen: "",
  productOpenTabs: ['colorsOpen'],
  ads: [],
  openProductInfo: [],
  cartData: {},
  rightSection:"main"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SAMPLE_TYPE:
      return {
        ...state,
        isDarkMode: !action.darkModeStatus,
      };

    case types.GET_ALL_PRODUCTS:
      return {
        ...state,
        items: [...action.payload],
        count: action.count,
        wishlist: [...action.wishlist],
        product: {},
        colors: [],
        images: [],
        ads: [...action.ads],
      };

    case types.RESET_FILTERS:
      return {
        ...initialState,
      };

    case types.GET_PRODUCT_DETAILS:
      return {
        ...state,
        product: { ...action.payload },
        pantones: [...action.pantones],
        colors: [...action.colors],
        orderDetails: {},
        productOpenTabs: [],
      };

    case types.GET_PRODUCT_DETAILS_MODIFY:
      return {
        ...state,
        product: { ...action.payload },
        pantones: [...action.pantones],
        colors: [...action.colors],
        orderDetails: { ...action.orderDetails },
        productOpenTabs: [],
      };

    case types.GET_PANTONES:
      return {
        ...state,
        pantones: [...action.pantones],
      };

    case types.SET_ORDERDETAILS_CUSTOM_COLOR:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          customColor: [...action.colors],
        },
      };

    case types.SET_SAMPLE_STATE:
      let tempOrderDetails = { ...state.orderDetails };
      delete tempOrderDetails.colorsAndSizes;
      tempOrderDetails.sample = action.value;
      tempOrderDetails.customColor = [];
      tempOrderDetails.addons = [];
      tempOrderDetails.totalQuantity = 1;
      tempOrderDetails.totalPrice = +action.totalPrice;
      // console.log(tempOrderDetails);
      return {
        ...state,
        orderDetails: { ...tempOrderDetails },
      };

    case types.SET_ORDERDETAILS:
      return {
        ...state,
        orderDetails: { ...action.orderDetails },
      };
    case types.SET_GRAPHICSOPEN:
      return {
        ...state,
        graphicsOpen: action.graphicsOpen,
      };
    case types.SET_PRODUCT_OPEN_TABS:
      return {
        ...state,
        productOpenTabs: action.productOpenTabs,
      };
    
    case types.SET_RIGHT_SECTION:
      return {
        ...state,
        rightSection: action.rightSection,
      };

    case types.SET_NEW_IMAGES:
      return {
        ...state,
        images: [...action.images],
      };

    case types.SET_FILTERS:
      return {
        ...state,
        items: [...action.products],
        noMoreProducts: action.noMoreProducts,
        count: action.count,
        ads: [...action.ads],
      };

    case types.SET_FILTERS_ONLY:
      return {
        ...state,
        filters: { ...action.filter },
      };
    case types.SET_TYPE_ONLY:
      return {
        ...state,
        type: action.productType,
      };
    case types.SET_CARTDATA:
      return {
        ...state,
        cartData: action.data,
      };

    case types.DISPLAY_NEXT_PRODUCTS:
      return {
        ...state,
        items: [...state.items, ...action.products],
        filters: { ...action.filter },
        noMoreProducts: action.products.length > 0 ? false : true,
        type: action.productType,
      };

    case types.SEND_PREORDER_EMAIL:
      return { ...state };

    case types.UPDATE_WISHLIST:
      return {
        ...state,
        wishlist: [...action.wishlist],
      };
    case types.SET_OPEN_PRODUCT:
      return {
        ...state,
        openProductInfo: [...action.product],
      };

    default:
      return state;
  }
};
