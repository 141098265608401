import { types } from "../actions/user/types";
const initialState = {
  isDarkMode: false,
  isAuthenticated: false,
  authLoading: false,
  phone: "",
  role: "",
  email: "",
  name: "",
  authorized: false,
  loading: false,
  loadingScreen: false,
  error: false,
  userId: "",
  account: {},
  assistant: "",
  password_token:"",
  userDetails:{}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SAMPLE_TYPE:
      return { ...state, isDarkMode: !action.darkModeStatus };
    case types.SET_USER:
      return { ...state, ...action.payload };
    case types.LOGIN_USER:
      return { ...state, ...action.payload };
    case types.SET_ASSISTANT:
      return { ...state, assistant: action.payload };
    case types.REGISTER_USER: {
      // console.log(action, 'new action')
      if (action.payload && action.payload.access.token) {
        return { ...state, isAuthenticated: true,...action.user };
      }
      return { ...state, isAuthenticated: false };
    }
    case types.USER_DETAILS:
      return { ...state, userDetails: action.userDetails };
    
    // case types.
    default:
      return state;
  }
};
