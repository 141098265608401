exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-payment-js": () => import("./../../../src/pages/checkout/payment.js" /* webpackChunkName: "component---src-pages-checkout-payment-js" */),
  "component---src-pages-checkout-plaid-js": () => import("./../../../src/pages/checkout/plaid.js" /* webpackChunkName: "component---src-pages-checkout-plaid-js" */),
  "component---src-pages-checkout-thank-you-js": () => import("./../../../src/pages/checkout/thank-you.js" /* webpackChunkName: "component---src-pages-checkout-thank-you-js" */),
  "component---src-pages-customer-custom-thank-you-js": () => import("./../../../src/pages/customer/custom/thank-you.js" /* webpackChunkName: "component---src-pages-customer-custom-thank-you-js" */),
  "component---src-pages-customer-meeting-js": () => import("./../../../src/pages/customer/meeting.js" /* webpackChunkName: "component---src-pages-customer-meeting-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-misc-member-details-js": () => import("./../../../src/pages/misc/member-details.js" /* webpackChunkName: "component---src-pages-misc-member-details-js" */),
  "component---src-pages-misc-user-details-js": () => import("./../../../src/pages/misc/user-details.js" /* webpackChunkName: "component---src-pages-misc-user-details-js" */),
  "component---src-pages-onboarding-brand-age-js": () => import("./../../../src/pages/onboarding/brand-age.js" /* webpackChunkName: "component---src-pages-onboarding-brand-age-js" */),
  "component---src-pages-onboarding-brand-focus-js": () => import("./../../../src/pages/onboarding/brand-focus.js" /* webpackChunkName: "component---src-pages-onboarding-brand-focus-js" */),
  "component---src-pages-onboarding-brand-location-js": () => import("./../../../src/pages/onboarding/brand-location.js" /* webpackChunkName: "component---src-pages-onboarding-brand-location-js" */),
  "component---src-pages-onboarding-brand-mission-js": () => import("./../../../src/pages/onboarding/brand-mission.js" /* webpackChunkName: "component---src-pages-onboarding-brand-mission-js" */),
  "component---src-pages-onboarding-brand-name-js": () => import("./../../../src/pages/onboarding/brand-name.js" /* webpackChunkName: "component---src-pages-onboarding-brand-name-js" */),
  "component---src-pages-onboarding-brand-type-js": () => import("./../../../src/pages/onboarding/brand-type.js" /* webpackChunkName: "component---src-pages-onboarding-brand-type-js" */),
  "component---src-pages-onboarding-brand-website-js": () => import("./../../../src/pages/onboarding/brand-website.js" /* webpackChunkName: "component---src-pages-onboarding-brand-website-js" */),
  "component---src-pages-onboarding-business-category-js": () => import("./../../../src/pages/onboarding/business-category.js" /* webpackChunkName: "component---src-pages-onboarding-business-category-js" */),
  "component---src-pages-onboarding-business-type-js": () => import("./../../../src/pages/onboarding/business-type.js" /* webpackChunkName: "component---src-pages-onboarding-business-type-js" */),
  "component---src-pages-onboarding-check-mail-js": () => import("./../../../src/pages/onboarding/check-mail.js" /* webpackChunkName: "component---src-pages-onboarding-check-mail-js" */),
  "component---src-pages-onboarding-choose-assistant-js": () => import("./../../../src/pages/onboarding/choose-assistant.js" /* webpackChunkName: "component---src-pages-onboarding-choose-assistant-js" */),
  "component---src-pages-onboarding-registration-info-js": () => import("./../../../src/pages/onboarding/registration-info.js" /* webpackChunkName: "component---src-pages-onboarding-registration-info-js" */),
  "component---src-pages-onboarding-registration-successful-js": () => import("./../../../src/pages/onboarding/registration-successful.js" /* webpackChunkName: "component---src-pages-onboarding-registration-successful-js" */),
  "component---src-pages-onboarding-resend-email-js": () => import("./../../../src/pages/onboarding/resend-email.js" /* webpackChunkName: "component---src-pages-onboarding-resend-email-js" */),
  "component---src-pages-onboarding-set-password-js": () => import("./../../../src/pages/onboarding/set-password.js" /* webpackChunkName: "component---src-pages-onboarding-set-password-js" */),
  "component---src-pages-onboarding-step-one-js": () => import("./../../../src/pages/onboarding/step-one.js" /* webpackChunkName: "component---src-pages-onboarding-step-one-js" */),
  "component---src-pages-onboarding-user-details-js": () => import("./../../../src/pages/onboarding/user-details.js" /* webpackChunkName: "component---src-pages-onboarding-user-details-js" */),
  "component---src-pages-orders-index-js": () => import("./../../../src/pages/orders/index.js" /* webpackChunkName: "component---src-pages-orders-index-js" */),
  "component---src-pages-orders-order-details-js": () => import("./../../../src/pages/orders/orderDetails.js" /* webpackChunkName: "component---src-pages-orders-order-details-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-products-cart-checkout-details-js": () => import("./../../../src/pages/products/cart/checkout-details.js" /* webpackChunkName: "component---src-pages-products-cart-checkout-details-js" */),
  "component---src-pages-products-cart-js": () => import("./../../../src/pages/products/cart.js" /* webpackChunkName: "component---src-pages-products-cart-js" */),
  "component---src-pages-products-collections-js": () => import("./../../../src/pages/products/collections.js" /* webpackChunkName: "component---src-pages-products-collections-js" */),
  "component---src-pages-products-pantone-book-js": () => import("./../../../src/pages/products/pantone-book.js" /* webpackChunkName: "component---src-pages-products-pantone-book-js" */),
  "component---src-pages-products-thank-you-js": () => import("./../../../src/pages/products/thank-you.js" /* webpackChunkName: "component---src-pages-products-thank-you-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-seo-js": () => import("./../../../src/pages/Seo.js" /* webpackChunkName: "component---src-pages-seo-js" */),
  "component---src-pages-test-redux-js": () => import("./../../../src/pages/test-redux.js" /* webpackChunkName: "component---src-pages-test-redux-js" */)
}

