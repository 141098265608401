export const types = {
  SAMPLE_TYPE: "SAMPLE_TYPE",
  GET_ALL_PRODUCTS: "GET_ALL_PRODUCTS",
  GET_PRODUCT_DETAILS: "GET_PRODUCT_DETAILS",
  GET_PANTONES:"GET_PANTONES",
  SET_ORDERDETAILS_CUSTOM_COLOR:"SET_ORDERDETAILS_CUSTOM_COLOR",
  SET_FILTERS:"SET_FILTERS",
  SET_FILTERS_ONLY:"SET_FILTERS_ONLY",
  DISPLAY_NEXT_PRODUCTS:"DISPLAY_NEXT_PRODUCTS",
  SET_LOADING:"SET_LOADING",
  SEND_PREORDER_EMAIL:"SEND_PREORDER_EMAIL",
  UPDATE_WISHLIST:"UPDATE_WISHLIST",
  SET_NEW_IMAGES:"SET_NEW_IMAGES",
  SET_SAMPLE_STATE:"SET_SAMPLE_STATE",
  SET_ORDERDETAILS:"SET_ORDERDETAILS",
  GET_PRODUCT_DETAILS_MODIFY:"GET_PRODUCT_DETAILS_MODIFY",
  SET_TYPE_ONLY:"SET_TYPE_ONLY",
  SET_GRAPHICSOPEN:"SET_GRAPHICSOPEN",
  SET_PRODUCT_OPEN_TABS:"SET_PRODUCT_OPEN_TABS",
  SET_OPEN_PRODUCT:"SET_OPEN_PRODUCT",
  RESET_FILTERS:"RESET_FILTERS",
  SET_CARTDATA:"SET_CARTDATA",
  SET_RIGHT_SECTION:"SET_RIGHT_SECTION",
};
